import {createI18n} from 'vue-i18n'


function getCurrentDomain() {
    // Получаем текущий URL страницы
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split("/");
    if (urlParts.length >= 3) {
      return urlParts[2];
    }
    return "";
  }

// const currentUrl = "https://mvp.ilavista.com";

let locale = "en";

const currentDomain = getCurrentDomain();
if (currentDomain.includes(".by")) {
    locale = "ru";
} else if (currentDomain.includes(".com")) {
    locale = "en";
}

document.addEventListener('DOMContentLoaded', function() {
    const body = document.body;
    if (locale === 'ru') {
      body.classList.add('app-ru');
    } else {
      body.classList.add('app-en');
    }
  });

const i18n = createI18n({
    locale: locale,
    messages: {
        en: {
            outsourcing_var: "Outsourcing",
            heading: "MVP Development",
            main_text: "MVP (Minimum Viable Product) is a basic version of your product with core features. This will help to ensure that your idea works, get feedback from early users, attract investors for further development and enter the market first, beating competitors",
            main_button: "Book a meet",
            about_heading: `About <br/> the service`,
            about_text: "The MVP-based approach allows you to quickly test a business idea, receive real feedback, and plan further steps",
            about_text2: "Without MVP, it is difficult to determine if the product will be in demand, and there is a high probability of spending a lot of time and resources on something that won't find its user",
            switcher_on: "MVP ON",
            switcher_off: "MVP OFF",
            advantages_title: "When MVP is vital",
            advantages_subtitle: "Launching an MVP is a good practice for any business, but there are situations when it is indispensable",
            list_title1: "New products and startups",
            list_title2: "Limited resources",
            list_title3: "Uncertain market needs",
            list_title4: "Fast time to market",
            list_title5: "Renewal and development",
            list_title6: "Hypothesis Testing",
            list_text1: "If you have new product idea or startup, MVP will allow you to check quickly how relevant is your product for users and whether it solves their problems",
            list_text2: "If you have a limited budget or time, an MVP will help you to focus on core features and get your product to market as quickly as possible",
            list_text3: "If you have a hypothesis about solving a user's problem, but don't know exactly what they're looking for, MVP will allow you to test and get feedback from real users",
            list_text4: "If you have strong competition and want to enter the market quickly, an MVP will allow you to enter the market faster, even if it is a minimal set of features",
            list_text5: "MVP is suitable for products and applications that plan to update constantly and evolve basing on user feedback",
            list_text6: "If you have several ideas about your product features, MVP allows you to test them quickly and determine the best option",
            quote_text: "The speed and quality of launching an MVP play a crucial role in business development. Regardless of the objectives, outsourcing the development to a professional team can be the best solution",
            features_title: "Why to choose us?",
            list_title7: "Transparency",
            list_title8: "Speed",
            list_title9: "Flexibility",
            list_title10: "Technologies",
            list_text7: "We work with flexible methodologies. Development is divided into stages to decompose tasks, better plan workload, and budget",
            list_text8: "When your idea is relevant, it's essential to be the first to implement it. We won't make you wait and will quickly create the first version of the product, ensuring you are confident in your superiority",
            list_text9: "Depending on your project, we will tailor the team to the required technologies, scope of work, and budget while maintaining efficiency and adhering to our development principles",
            list_text10: "We work with Laravel and vue.js frameworks, enabling us to build modern, scalable projects of any complexity",
            projects_title: "Recent successful projects",
            project_subtitle1: "Web Development",
            project_subtitle2: "Web Development, ERP System and Flutter",
            project_desc1: "Online platform for posting and searching for information about relocation",
            project_desc2: "ERP system for managing logistics company routes with an application for drivers",
            project_desc3: "Online platform for auditing companies for the moral and psychological atmosphere in the team",
            btn_more: "Read more",
            btn_back: "Base to landing",
            project_subtitle: "Stages of development",
            stage_1: "Data Collection",
            stage_2: "Competitive Analysis and UX Research",
            stage_3: "Technical Specification",
            stage_4: "UX/UI Prototypes",
            stage_5: "Prototype Testing with Focus Group",
            stage_6: "Back-end (Documentation and Testing)",
            stage_7: "Front-end",
            stage_8: "Product Testing",
            stage_9: "Production Server Architecture Setup",
            stage_10: "Launch",
            request_title: "Request",
            result_title: "Result",
            nowadays_title: "Nowadays",
            request_text: "The client approached us with the idea of creating a relocation platform, where each user could find relevant information and share their experience how to move to another country with others. Another task was to enable thematic commercial companies to publish professional content on the platform",
            result_text: "The platform has been successfully launched. We have implemented user-friendly content filters based on the region of relocation, visa type, and visit purpose to provide personalized information. Users who have relocated previously can share their experiences in blog format and receive feedback. For commercial companies, we have developed a personal account feature allowing them to subscribe and offer their services.The development and implementation of the platform took 6 months",
            nowadays_text:"We are currently attracting content creators to the platform. Testing of various pricing models for monetization is underway, and we are actively working on SEO optimization to increase website traffic",
            request_text2: "The logistics company GTE turned to us for automation of processes, to eliminate errors in documentation and the human factor in the process of work. But the client had another idea - to create a convenient application for drivers so that they could plan their work and routes",
            result_text2: "We have developed a minimal product for testing and invited real employees of the company to start using the system in order to take into account the opinion of employees in further development and add only those functions of the system that they need to work. Our team has created an ERP system with an automatic application process and the formation of documentation on orders and routes. The business owner receives management reports in the form of clear dashboards. In the application, we have made a convenient data accounting system in which drivers plan their working days, routes, stops and refueling",
            nowadays_text2:"During the work of the ERP system, sales managers and logisticians have accelerated their work by 30-40% due to the automation of document flow and order planning.The ERP system and the application are at the stage of implementation in the company. In parallel, we are training the company's employees to work with a new system",
            request_text3: "We were approached by the Global Impact Teams team - they conduct audits of successful companies' staff for the moral and psychological atmosphere within the team. The request was to develop a product that has the ability to test a large number of people simultaneously at the company level, as well as to have the collected analytical data processed automatically, ordered and displayed in the form of understandable dashboards, etc to simplify the work with a huge amount of information",
            result_text3: "There are few analogues on the market and to avoid risks we have developed a product with minimal functionality in order to test the viability of the idea and the demand for it with real users. That’s how we found out that companies and coaches are interested in the test and began full-scale development. We took the test results into account and launched a platform where business owners or their employees can create an automatic audit of the company, conduct surveys among a large number of participants simultaneously, and get analytics based on the results",
            nowadays_text3:"The platform is in the stage of attracting the first users to gather feedback and based on it, proceed with improvements",
            process_title: "Process",
            process_step_1_title: "Start",
            process_step_2_title: "Development and Launch",
            process_step_3_title: "Expansion",
            process_step_1: "We will test your hypothesis, conduct UX research, outline documentation, and create high-level prototypes for you to test all your assumptions",
            process_step_1_timeline: "Timeline: 1-2 months",
            process_step_2: "At this stage, our team of designers and developers will build a fully functional product and launch it into production. The result will be a ready-to-use MVP, where you can test hypotheses with real users",
            process_step_2_timeline: "Timeline: 2-4 months",
            process_step_3: "After the launch, we will continue testing new hypotheses, developing, and improving your idea until the project becomes strong and self-sufficient",
            process_step_3_timeline: "Timeline: as long as you need",
            quote_text_second: "As an experienced MVP development company, we know how to turn your ideas into a working asset. After development, our product will be stable, reliable, and high-performing, allowing you to quickly enter the market with minimal risks",
            footer_title: "Ready to unlock the potential of your idea?",
            book_btn: "Book a free expert session",
            footer_quote: "Ready to unlock the potential of your idea?"

        },
        ru: {
            outsourcing_var: "Аутсорсинг",
            heading: "Разработка MVP",
            main_text: "MVP (Минимально Жизнеспособный Продукт) – это базовая версия вашего продукта с основными функциями. Это поможет убедиться, что ваша идея работает, а также получить обратную связь от пользователей, привлечь инвесторов для дальнейшего развития и выйти на рынок первыми, обойдя конкурентов",
            main_button: "Обсудить проект",
            about_heading: "Как это работает",
            about_text: "Вы можете сосредоточиться на основных функциях продукта, быстро проверить бизнес-идею, получить обратную связь от реальных пользователей и продумать дальнейшие шаги",
            about_text2: "Сложно определить, будет ли продукт востребован, поэтому возрастает риск потратить много времени и ресурсов на то, что не найдет своего пользователя",
            switcher_on: "С MVP",
            switcher_off: "БЕЗ MVP",
            advantages_title: "Запуск MVP",
            advantages_subtitle: "- это хорошая практика для каждого бизнеса, но в некоторых случаях он особенно необходим",
            list_title1: "Новые идеи или стартапы",
            list_title2: "Ограниченные ресурсы",
            list_title3: "Рыночная неопределенность",
            list_title4: "Для быстрого выхода на рынок",
            list_title5: "Обновление и развитие",
            list_title6: "Тестирования идеи/гипотез",
            list_text1: "Позволяет быстро проверить потенциал вашего продукта и интерес пользователей к нему.",
            list_text2: "MVP помогает сосредоточиться на основных функциях и быстро запустить продукт на рынок с минимальными затратами и рисками",
            list_text3: "С MVP можно тестировать гипотезы и получать обратную связь от реальных пользователей",
            list_text4: "MVP помогает противостоять конкуренции и быстро выделиться на рынке",
            list_text5: "MVP подходит для продуктов, требующих регулярных обновлений на основе отзывов пользователей.",
            list_text6: "Позволяет проверить разные варианты и выбрать наиболее перспективный",
            quote_text: "Скорость и качество запуска минимальной версии продукта играют решающую роль в развитии бизнеса. Независимо от ваших целей, если вы передадите разработку команде профессионалов на аутсорсинг, это станет лучшим решением",
            features_title: "Почему ILAVISTA?",
            list_title7: "Прозрачность",
            list_title8: "Скорость",
            list_title9: "Гибкость",
            list_title10: "Технологии",
            list_text7: "Мы работаем с гибкими методологиями. Разработка разделена на этапы, чтобы декомпозировать задачи, лучше планировать нагрузку и бюджет",
            list_text8: "Когда ваша идея актуальна, важно стать в ее реализации первым. Мы не заставим вас ждать и быстро сделаем первую версию продукта, с которой вы можете быть уверены в своем превосходстве",
            list_text9: "В зависимости от вашего проекта, мы подберем команду под необходимые технологии, объем работ и бюджет, сохраняя эффективность и следуя нашим принципам разработки",
            list_text10: "Мы работаем с фреймворками Laravel и vue.js - это позволяет нам создавать современные масштабируемые проекты любой сложности на всех типах устройств",
            projects_title: "Актуальные проекты с MVP",
            project_subtitle1: "Веб-разработка",
            project_subtitle2: "Веб-разработка, ERP-Система и Flutter",
            project_desc1: "Онлайн-платформа для размещения и поиска информации о релокации",
            project_desc2: "ERP-система для управления маршрутами логистической компании с приложением для водителей",
            project_desc3: "Платформа для аудита компаний на предмет морально-психологической атмосферы в коллективе",
            btn_more: "Подробнее",
            btn_back: "Назад",
            project_subtitle: "Этапы разработки",
            stage_1: "Сбор данных",
            stage_2: "Конкурентный анализ и UX- исследование",
            stage_3: "Техническое задание",
            stage_4: "UX/UI  Прототипы",
            stage_5: "Тестирование прототипов на фокус группе",
            stage_6: "Back-end (Документация и тесты)",
            stage_7: "Front-end",
            stage_8: "Тестирование продукта",
            stage_9: "Настройка архитектуры сервера и выгрузка проекта на него",
            stage_10: "Запуск",
            request_title: "Запрос",
            result_title: "Результат",
            nowadays_title: "Сегодня",
            request_text: "Клиент обратился к нам с идеей создания платформы-форума с элементами социальной сети для релокации, где каждый пользователь мог бы найти актуальную информацию и поделиться своим опытом переезда в другую страну с другими.Другая задача состояла в том, чтобы дать возможность тематическим коммерческим компаниям публиковать профессиональный контент на платформе",
            result_text: "MVP помог проверить актуальность продукта и получить отзывы от реальных пользователей. Это позволило нам исправить неочевидные ошибки, протестировать идеи и выпустить на рынок стабильную версию продукта.Мы внедрили удобные фильтры контента, основанные на регионе переезда, типе визы и цели визита, чтобы предоставлять персонализированную информацию. Пользователи, которые переехали ранее, могут поделиться своим опытом в формате блога и получить обратную связь. Для коммерческих компаний мы разработали функцию личного кабинета, позволяющую им подписываться и предлагать свои услуги. Разработка и внедрение платформы заняли 6 месяцев",
            nowadays_text:"В настоящее время мы активно работаем над SEO-оптимизацией для увеличения посещаемости сайта, привлекаем на платформу авторов контента и тестируем различные модели ценообразования для монетизации",
            request_text2: "Логистическая компания GTE обратилась к нам за ERP-системой для автоматизации процессов внутри компании и устранения ошибок в документации. Но у клиента была еще одна идея - создать удобное приложение для водителей, где они могли бы планировать свою работу и маршруты",
            result_text2: "В начале разработки мы создали минимальный продукт для тестирования и попросили менеджеров по продажам и логистов компании начать использовать систему, чтобы добавить только необходимые для их работы функции.Основываясь на потребностях сотрудников компании, наша команда разработала ERP-систему с автоматической подачей заявок и заполнением документов по заказам и маршрутам. Владелец бизнеса получает отчеты в виде понятных информационных панелей. В приложение мы внедрили удобную систему учета данных, где водители могут планировать свои рабочие дни, маршруты, остановки и дозаправки",
            nowadays_text2:"Мы обучаем сотрудников компании работе с новыми процессами. ERP-система помогла менеджерам по продажам и логистам ускорить свою работу на 30-40% за счет автоматизации документооборота и планирования заказов. ERP-система и приложение находятся на стадии внедрения в компании",
            request_text3: "К нам обратилась команда Global Impact Teams - они проводят аудиты персонала компаний на предмет психологической атмосферы в коллективе. От нас требовалось разработать продукт для тестирования большого количества сотрудников компании одновременно. Чтобы упростить работу с огромным объемом информации, результаты тестов должны автоматически обрабатываться, систематизироваться и отображаться в понятном формате",
            result_text3: "Мы разработали продукт с минимальной функциональностью, чтобы снизить риски и проверить идею на жизнеспособность. После теста платформы на реальных пользователях мы выяснили, что крупные компаний и коучи действительно заинтересованы в аудите, поэтому начали разработку полной версии. В итоге мы запустили платформу, где владельцы бизнеса или их сотрудники могут создать автоматический аудит компании, проводить опросы среди большого количества участников одновременно и просматривать аналитику на основе полученных результатов",
            nowadays_text3:"Платформа находятся на стадии привлечения первых пользователей, чтобы собрать обратную связь и на ее основе приступить к улучшениям",
            process_title: "Процесс разработки",
            process_step_1_title: "Старт",
            process_step_2_title: "Разработка и запуск",
            process_step_3_title: "Развитие",
            process_step_1: "Мы изучим вашу идею, сделаем UX-исследование, опишем документацию и сделаем высокоуровневые прототипы, на которых вы сможете протестировать свои гипотезы",
            process_step_1_timeline: "Сроки: 1-2 месяца",
            process_step_2: "На этом этапе команда дизайнеров и разработчиков создаст полноценный продукт и запустит его в продакшн. В результате будет готова MVP, где можно проверять гипотезы уже на реальных пользователях",
            process_step_2_timeline: "Сроки: 2-4 месяца",
            process_step_3: "После запуска мы продолжим развивать и усовершенствовать вашу идею, пока проект не станет сильным и самостоятельным.",
            process_step_3_timeline: "Сроки: столько, сколько вам будет нужно",
            quote_text_second: "После разработки продукт будет стабильным и надежным, поэтому вы сможете быстро выйти с ним на рынок с минимальными рисками. Как опытная компания по разработке MVP, мы знаем, как превратить ваши идеи в эффективный рабочий ресурс",
            footer_title: "Готовы раскрыть потенциал своей идеи?",
            book_btn: "Обсудить проект",
            footer_quote: "Готовы раскрыть потенциал своей идеи?"
        }
    }
})

export default i18n;