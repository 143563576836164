import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import i18n from "./locales/i18n";
import gsap from "gsap";

import { ScrollTrigger, MotionPathPlugin, TextPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, TextPlugin);
createApp(App).use(gsap).use(i18n).mount("#app");

//animations

//анимация для самого первого блока
const tl = gsap.timeline();
tl.fromTo(".logo", { opacity: 0, scale: 0.6, y: 1 }, { y: 0, scale: 1, opacity: 1, duration: 1,  ease: "linear" });
tl.fromTo(
  ".main_inner-text",
  { opacity: 0, scale: 0.6, y: 1},
  { y: 0, scale: 1, opacity: 1, duration: 1, ease: "linear" },
  0
);
tl.fromTo(
  ".image-container",
  { opacity: 0 },
  { opacity: 1, ease: "slow", duration: 2 },
  0
);

//анимация для падающих слоев в process
const timeline = gsap.timeline({
  scrollTrigger: {
    trigger: ".process",
    start: "top center",
    end: "bottom center",
  },
});

document.querySelectorAll(".process_img").forEach(function (box) {
  timeline.from(box, {
    duration: 0.5,
    opacity: 0,
    y: 100,
    ease: "back.out",
  });
});






// Анимация для features
const tml = gsap.timeline();
document.querySelectorAll(".item.text").forEach(function (box) {
  tml.from(box, {
    duration: 0.5,
    opacity: 0,
    y: 20,
    scale: 0.4,
    ease: "back.out",
  });
});

ScrollTrigger.create({
  animation: tml,
  trigger: ".features",
  start: "top center",
  endTrigger: ".features",
  end: "bottom 65%",
});

//анимация для карточек на разных экранах
ScrollTrigger.matchMedia({
  // desktop
  "(min-width: 421px)": function () {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".list_box",
        duration: 1,
        ease: "back.out",
      },
    });
    tl.from(".list_box", {scale: 0.8, opacity: 0, duration: 1 }).to(".list_box", { duration: 1, opacity: 1, scale: 1});
  },

  // mobile
  "(max-width: 420px)": function () {
    const listBoxes = document.querySelectorAll(".list_box");

    listBoxes.forEach((listBox) => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: listBox,
          start: "top 10%",
          endTrigger: "#qoute",
          end: "bottom 80%+=80px",
          pin: true,
          stagger: {
            each: 0.2,
          },
          pinSpacing: false,
        },
      });
      tl.to(listBox, { opacity: 1 }).to(listBox, { opacity: 1 });
    });
  },
});


ScrollTrigger.matchMedia({
  "(min-width: 320px)": function () {
    const sectionElements = document.querySelectorAll(".section");

    sectionElements.forEach((sectionBox) => {
      let text = gsap.timeline({
        scrollTrigger: {
          trigger: sectionBox,
          duration: 1,
        },
      });

      text.from(sectionBox, { y: 20, opacity: 0, scale: 0.6,  duration: 1 }).to(sectionBox, { duration: 1, opacity: 1, scale: 1, y: 0 });
    });
  },
});

//animation для цитаты
ScrollTrigger.matchMedia({
  "(min-width: 320px)": function () {
    const quoteTextElements = document.querySelectorAll(".text-animated");

    quoteTextElements.forEach((quoteText) => {
      let text = gsap.timeline({
        scrollTrigger: {
          trigger: quoteText,
          duration: 1,
        },
      });

      text.from(quoteText, { scale: 0.8, opacity: 0, duration: 1 }).to(quoteText, { duration: 1, opacity: 1, scale: 1 });
    });
  },
});


// для маленького квадрата в about
const switcherOnButton = document.getElementById("switcherOn");
const squareImage = document.getElementById("squareImage");
squareImage.style.setProperty(
  "--image-left",
  `${switcherOnButton.offsetWidth}px`
);
switcherOnButton.addEventListener("click", () => {
  squareImage.style.setProperty(
    "--image-left",
    `${switcherOnButton.offsetWidth}px`
  );
});
const switcherOffButton = document.getElementById("switcherOff");
switcherOffButton.addEventListener("click", () => {});

