<template>
  <div class="modal">
    <button class="btn_back" @click="closeModal">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M0.646446 8.64645C0.451185 8.84171 0.451185 9.15829 0.646446 9.35355L3.82843 12.5355C4.02369 12.7308 4.34027 12.7308 4.53553 12.5355C4.7308 12.3403 4.7308 12.0237 4.53553 11.8284L1.70711 9L4.53553 6.17157C4.7308 5.97631 4.7308 5.65973 4.53553 5.46447C4.34027 5.2692 4.02369 5.2692 3.82843 5.46447L0.646446 8.64645ZM17 8.5L1 8.5V9.5L17 9.5V8.5Z"
          fill="black"
        />
      </svg>
      {{ $t("btn_back") }}
    </button>

    <div v-if="selectedProject == 0">
      <div class="modal_inner">

        <button class="close_btn text_lg color_primary" @click="closeModal">
          <img src="./../assets/images/svg/close.svg" alt="close-img">
          Close
        </button>


        <AppSidebarVue class="col-3" />

        <div class="modal_content content col-9">
          <div class="content_request">
              <h2 class="heading_xl color_primary">{{ $t("request_title") }}</h2>
              <p class="heading_sm color_main">{{ $t("request_text") }}</p>
          </div>
          <div class="content_result">
            <h2 class="heading_xl color_primary">{{ $t("result_title") }}</h2>
            <p class="heading_sm color_main">{{ $t("result_text") }}</p>
          </div>
          <div class="content_nowadays">
            <h2 class="heading_xl color_primary">{{ $t("nowadays_title") }}</h2>
            <p class="heading_sm color_main">{{ $t("nowadays_text") }}</p>

          </div>
        </div>


        
      </div>
    </div>
    <div v-else-if="selectedProject == 1">

      <div class="modal_inner">

        <button class="close_btn text_lg color_primary" @click="closeModal">
          <img src="./../assets/images/svg/close.svg" alt="close-img">
          Close
        </button>


        <AppSidebarVue class="col-3" />

        <div class="modal_content content col-9">
          <div class="content_request">
              <h2 class="heading_xl color_primary">{{ $t("request_title") }}</h2>
              <p class="heading_sm color_main">{{ $t("request_text2") }}</p>
          </div>
          <div class="content_result">
            <h2 class="heading_xl color_primary">{{ $t("result_title") }}</h2>
            <p class="heading_sm color_main">{{ $t("result_text2") }}</p>
          </div>
          <div class="content_nowadays">
            <h2 class="heading_xl color_primary">{{ $t("nowadays_title") }}</h2>
            <p class="heading_sm color_main">{{ $t("nowadays_text2") }}</p>

          </div>
        </div>
      </div>
    </div>
    <div v-else-if="selectedProject == 2">
    
      <div class="modal_inner">


        <button class="close_btn text_lg color_primary" @click="closeModal">
          <img src="./../assets/images/svg/close.svg" alt="close-img">
          Close
        </button>



        <AppSidebarVue class="col-3" />

        <div class="modal_content content col-9">
          <div class="content_request">
              <h2 class="heading_xl color_primary">{{ $t("request_title") }}</h2>
              <p class="heading_sm color_main">{{ $t("request_text3") }}</p>
          </div>
          <div class="content_result">
            <h2 class="heading_xl color_primary">{{ $t("result_title") }}</h2>
            <p class="heading_sm color_main">{{ $t("result_text3") }}</p>
          </div>
          <div class="content_nowadays">
            <h2 class="heading_xl color_primary">{{ $t("nowadays_title") }}</h2>
            <p class="heading_sm color_main">{{ $t("nowadays_text3") }}</p>

          </div>
        </div>
      </div>
    
    </div>
  </div>
</template>

<script>
import AppSidebarVue from "./AppSidebar.vue";

export default {
  components: {
    AppSidebarVue,
  },
  props: ["selectedProject"],
  // computed: {
  //   projectData() {
  //     return {
  //       title: this.$t("project_0_title"),
  //     };
  //   },
  // },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>

